import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How data visualization saved 40,000+ lives this spring",
  "description": "Social distancing saved lives this spring. Dataviz helped people understand why it matters",
  "date": "2020-05-25T09:13:00.000Z",
  "published": "2020-05-25T09:13:00.000Z",
  "image": "../../images/articles/flatten-the-curve.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Last week Slovenia became `}<a parentName="p" {...{
        "href": "https://www.aljazeera.com/news/2020/05/slovenia-european-nation-declare-epidemic-home-200515084142959.html"
      }}>{`the first EU country to beat Covid-19`}</a>{` with just 105 deaths – 0.05 per 1000. My mum was excited as heck.`}</p>
    <p><em parentName="p">{`"Yay we're going back to normal! No more of this bullshit, I hate wearing masks"`}</em>{`.`}</p>
    <p>{`Meanwhile in San Francisco we're still heming and hawing. Should we? Shouldn't we? Maybe a little? `}<a parentName="p" {...{
        "href": "https://www.sfchronicle.com/local-politics/article/Bay-Area-must-shelter-in-place-Only-15135014.php"
      }}>{`One of the first to shelter-in-place`}</a>{` we're now at `}<em parentName="p">{`"Yeah maybe"`}</em>{`.`}</p>
    <p>{`Work from home if possible, stay inside please. Wear masks always.`}</p>
    <p>{`Sweden never went into lockdown at all.`}</p>
    <p>{`Now Sweden has the `}<a parentName="p" {...{
        "href": "https://www.telegraph.co.uk/news/2020/05/20/sweden-becomes-country-highest-coronavirus-death-rate-per-capita/"
      }}>{`highest death rate per capita`}</a>{` and San Francisco barely registers with 40 deaths – 0.04 per 1000. New York went into `}<a parentName="p" {...{
        "href": "https://ny.curbed.com/2020/3/20/21187022/coronavirus-new-york-shutdown-shelter-in-place"
      }}>{`full lockdown just 4 days after SF`}</a>{` and they're one of America's strongest hotspots. 2239 deaths (1.3/1000) in Manhattan alone.`}</p>
    <p><a parentName="p" {...{
        "href": "https://coronavirus.jhu.edu/map.html"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/GXLYAAI.png",
          "alt": null
        }}></img></a></p>
    <p>{`Exponential growth boggles the mind.`}</p>
    <p>{`4 day difference in response plus a higher population density and you get `}<em parentName="p">{`orders of magnitude`}</em>{` different results. 🤯`}</p>
    <h2 {...{
      "id": "how-dataviz-saves-lives"
    }}>{`How dataviz saves lives`}</h2>
    <p>{`Remember back to March. What was it like? Who were you listening to? How'd you decide what to do?`}</p>
    <p>{`I bet you remember "flatten the curve".`}</p>
    <p>{`Slow the spread and maybe we can buy enough time to figure out what's going on. To really understand this novel disease. To avoid swamping hospitals and making it worse.`}</p>
    <p>{`Above all, it was this image that said it all.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.nytimes.com/article/flatten-curve-coronavirus.html"
      }}><img parentName="a" {...{
          "src": "https://static01.nyt.com/images/2020/03/22/science/11SCI-VIRUS-CURVE1/11SCI-VIRUS-TRACKER1-jumbo.jpg",
          "alt": null
        }}></img></a></p>
    <p>{`One image, instant understanding.`}</p>
    <p><em parentName="p">{`"Oh right, I should stay home. Let's not break the rules this time."`}</em></p>
    <p>{`I remember mid January some of us in a Slack group were looking at the `}<a parentName="p" {...{
        "href": "https://coronavirus.jhu.edu/map.html"
      }}>{`John Hopkins Dashboard`}</a>{` and thinking: `}<em parentName="p">{`"This doesn't look so good. It's spreading fast in China"`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://coronavirus.jhu.edu/map.html"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/T4X8xLn.png",
          "alt": null
        }}></img></a></p>
    <p>{`By January 23rd Wuhan was in lockdown. January 31st Italy had its first case. Soon after it's everywhere.`}</p>
    <p>{`By mid February online activists urge people to stay home. The `}<a parentName="p" {...{
        "href": "https://staythefuckhome.com/"
      }}>{`#StayTheFuckHome`}</a>{` movement is born.`}</p>
    <p>{`One by one countries start banning large gatherings. First 100+, then 50+, then 5+. Conferences got cancelled, concerts banned, movie theaters shut down. Grocery stores limit attendance. Toilet paper runs out.`}</p>
    <p>{`Life goes into limbo. We wait. Standing 6ft apart.`}</p>
    <p>{`All because of graphs like this`}</p>
    <p><a parentName="p" {...{
        "href": "https://ourworldindata.org/coronavirus-usa?country=USA+CHN+IRN+SWE+ITA+GBR+SVN+ESP"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/TVGkifP.gif",
          "alt": null
        }}></img></a></p>
    <p>{`And this`}</p>
    <p><a parentName="p" {...{
        "href": "https://ourworldindata.org/coronavirus-usa?country=USA+CHN+IRN+SWE+ITA+GBR+SVN+ESP"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/YJDtRuP.gif",
          "alt": null
        }}></img></a></p>
    <p>{`And simulations like the Washington Post's that shows the difference between viral spread with and without measures.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.washingtonpost.com/graphics/2020/world/corona-simulator/"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/9I9l4GO.gif",
          "alt": null
        }}></img></a></p>
    <p>{`Or the wonderful `}<a parentName="p" {...{
        "href": "https://peopleofthepandemicgame.com/"
      }}>{`People of the Pandemic`}</a>{` game that takes lockdown measures and translates them into `}<em parentName="p">{`"Here's what happens if you actually listen. Or if you don't"`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://peopleofthepandemicgame.com/"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/XUGaUME.gif",
          "alt": null
        }}></img></a></p>
    <p>{`Ultimately everyone made their own bets.`}</p>
    <p>{`Some wore masks, some didn't. Some left the house, others stayed inside for 7 weeks. Some disinfected everything, others were blasé about it.`}</p>
    <p>{`And it's these data visualizations that helped us make our choices.`}</p>
    <h2 {...{
      "id": "did-it-work"
    }}>{`Did it work?`}</h2>
    <p>{`We'll never know for sure. You can't A/B test reality.`}</p>
    <p>{`But we can guess. Run some math on the back of a napkin. Compare countries with and without stay-at-home orders.`}</p>
    <p>{`Sweden famously didn't lock down.`}</p>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/COVID-19_pandemic_in_Sweden"
      }}>{`4029 deaths`}</a>{` since January 24th according to wikipedia. That's 33/day. 0.003/day/1000people.`}</p>
    <p>{`Compare that to USA with a rolling lockdown response as each state saw fit. `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/COVID-19_pandemic_in_the_United_States"
      }}>{`91,941 deaths`}</a>{` since January 13th according to wikipedia. That's 691/day. 0.002/day/1000people.`}</p>
    <p>{`It might not look like much but that 0.001/day difference means USA saved 40,000 lives with social distancing.`}</p>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "https://media3.giphy.com/media/8W9q3R3kk6inJdnutC/giphy-loop.mp4?cid=4ac046a2yn7r49wipf0nbdrdtiy133u9y5m9c605blb8wn4r&rid=giphy-loop.mp4&ct=g",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <p>{`That's despite crazy stories of people protesting against lockdowns, holding mass gatherings on purpose, and the general human tendency to not quite listen to what your parents say.`}</p>
    <p>{`We only listen when we understand `}<strong parentName="p"><em parentName="strong">{`why`}</em></strong>{` my friend.`}</p>
    <p>{`And that's how I think dataviz saved 40,000 lives in the US alone. 💪`}</p>
    <p>{`Did dataviz help you? Hit reply`}</p>
    <p>{`Cheers,`}<br />{`
~Swizec`}</p>
    <p>{`PS: this article uses back-of-the-napkin math. We'll know real numbers once it's all over and data scientists do their thing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      